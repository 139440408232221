@keyframes moveText {
    50% {
      transform: translateX(100%);
    }
    /* 100% {
      transform: translateX(-30%);
    } */
    /* 10% {
      transform: translateX(10%);
    } */
    /* 10% {
      transform: translateX(0%);
    } */

  }
  
  .moving-text {
    animation: moveText 3s ease-in-out infinite;
  }