.jsMainParent {
    width: 100vw;
    height: 73vh;
    background-color: #f4f3f0;
  }
  
  .jsLeft {
    position: relative;
    top: 5rem;
    left: 13rem;
  }
  
  .readBtn {
    border: none;
    background-color: #252525;
    width: 11rem;
    height: 3rem;
    color: white;
    transition: 350ms ease;
    left: 1.8rem;
    top: 3rem;
    position: relative;
  }
  
  .readBtn:hover {
    background-color: #173f35;
    color: rgb(255, 255, 255);
  }
  
  .jsImg {
    position: relative;
    top: -10rem;
    left: 40rem;
    width: 22rem;
    object-fit: cover;
  }
  
  .jsRightText {
    left: 43rem;
    top: -8rem;
  }
  
  .lh6 {
    line-height: 6.5rem;
  }
  
  /* Remove space from the bottom */
  .jsMainParent p {
    margin-bottom: 0;
  }