


  .logo-spinner {
    perspective: 1000px;
  }
  
  .logo-spinner.flip {
    animation: flip-animation 1.2s infinite;
    animation-fill-mode: both;
    transform-style: preserve-3d;
  }
  
  @keyframes flip-animation {
    0% {
      transform: rotateY(0deg);
    }
    50% {
      transform: rotateY(360deg);
    }
    100% {
      transform: rotateY(360deg);
    }
  }