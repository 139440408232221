.logo-spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .logo {
    width: 100px; /* Adjust the width as needed */
    height: auto;
  }
  
  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 2s linear infinite;
    margin-top: 20px;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }